import React from 'react';
import styled from 'styled-components';
import facebookIcon from '../../static/facebook-icon.svg';
import instagramIcon from '../../static/instagram-icon.svg';
import messengerIcon from '../../static/messenger-icon.svg';
import Icon from './Icon';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: static;
  width: unset;

  .contactHero > & {
    justify-content: center;
    svg path {
      fill: white;
    }
  }

  ${({ theme }) => theme.mq.desktop} {
    ul > & {
      padding-left: 34px;
    }
  }
`;

const StyledIcon = styled(Icon)`
  height: 25px;
  width: auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 0;

  &:first-child {
    margin-top: auto;
  }

  svg {
    height: 100%;
    width: auto;
  }

  path {
    fill: black;
  }
`;

const SocialMenu = () => (
  <StyledWrapper>
    <a
      href="https://pl-pl.facebook.com/Pracownia-Florystyczna-Lawendowy-k%C4%85cik-913695802073065/"
      rel="noopener norefferer"
    >
      <StyledIcon src={facebookIcon} />
    </a>
    <a href="http://m.me/913695802073065" rel="noopener norefferer">
      <StyledIcon src={messengerIcon} />
    </a>
    <a
      href="https://www.instagram.com/lawendowy_floristicworkshop/"
      rel="noopener norefferer"
    >
      <StyledIcon src={instagramIcon} />
    </a>
  </StyledWrapper>
);

export default SocialMenu;
