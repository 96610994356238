import React from 'react';
import 'normalize.css';
import { ThemeProvider } from 'styled-components';
import CookieConsent from 'react-cookie-consent';
import GatsbyLink from 'gatsby-link';
import { theme } from '../styles/theme';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import Nav from './Nav';
import Footer from './Footer';

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <ThemeProvider theme={theme}>
        <>
          <Nav />
          {children}
          <Footer />
          <CookieConsent
            location="bottom"
            buttonText="Rozumiem"
            cookieName="gatsby-plugin-gdpr-cookies"
            style={{
              background: '#402f66',
              fontFamily: 'Raleway',
              textAlign: 'center',
              zIndex: 9999,
            }}
            buttonStyle={{
              background: '#F0E9F1',
              color: '#4e503b',
              fontSize: '18px',
              fontFamily: "'Dancing Script',cursive",
            }}
            expires={150}
          >
            <span style={{ fontSize: '10px' }}>
              Strona wykorzystuje pliki cookies m.in. w celu poprawienia jej
              dostępności czy aby zbierać dane, dotyczące ruchu na stronie.
              Każdy może sam decydować o tym czy dopuszcza pliki cookies,
              ustawiając odpowiednio swoją przeglądarkę. Więcej informacji
              znajdziesz w{' '}
              <GatsbyLink to="/polityka-prywatnosci" style={{ color: 'white' }}>
                Polityce prywatności
              </GatsbyLink>
              .
            </span>
          </CookieConsent>
        </>
      </ThemeProvider>
    </>
  );
}
