import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`
    
  html {
    font-family: 'Raleway', sans-serif;
    color: var(--black);
    letter-spacing: 1px;
  }
  
  .highlight {
     font-family: 'Dancing Script', cursive;
     font-weight: bold;
     font-size: 2.2rem;
     line-height: 2.6rem;
  }
  
   a {
    color: var(--black);
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
  }

  .center {
    text-align: center;
  }
  
`;

export default Typography;
