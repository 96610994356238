import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import Hamburger from './Hamburger';
import SocialMenu from './SocialMenu';

const StyledWrapper = styled.nav`
  display: flex;
  position: fixed;
  justify-content: space-between;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  background-color: ${({ theme }) => theme.white};
  transition: box-shadow 0.3s ease, max-height 0.15s ease-out;
  max-height: 250px;

  ${({ theme }) => theme.mq.desktop} {
    align-items: center;
    justify-content: center;
  }

  ${(props) => {
    if (props.scrollactive === 'true' || props.ishomepage !== 'true') {
      return `
            box-shadow: 0 2px 8px #F0E9F1;
            max-height: 100px;
            transition: max-height 0.25s ease-in;
            `;
    }
    return `
        transition: box-shadow .3s ease, max-height 0.15s ease-out;
        max-height: 250px;  
        `;
  }}
`;

const MenuLinksWrapper = styled.ul`
  margin: 0 auto;
  padding: 0;
  flex-direction: row;
  justify-content: center;
  display: none;

  ${({ theme }) => theme.mq.desktop} {
    display: flex;
  }
`;

const MenuLink = styled(Link)`
  text-decoration: none;
  list-style: none;
  /*
  margin: 10px; 
  */
  color: ${({ theme }) => theme.black};
  position: relative;
  display: none;
  vertical-align: middle;
  margin: 10px 40px;
  font-size: ${({ theme }) => theme.font.size.xs};
  text-transform: uppercase;
  letter-spacing: 1px;

  ${({ theme }) => theme.mq.desktop} {
    display: flex;
  }

  :hover:before {
    visibility: visible;
    width: 100%;
  }

  ::before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.black};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &.active {
    ::before {
      content: '';
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) => theme.black};
      visibility: visible;
      transition: all 0.3s ease-in-out;
      width: 100%;
    }
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  display: flex;
  height: 100px;
  width: 100px;
  margin-left: 2rem;

  :visited {
    color: ${({ theme }) => theme.black};
  }

  ${({ theme }) => theme.mq.desktop} {
    margin-left: 0;

    ${(props) => {
      if (props.scrollactive === 'true' || props.ishomepage !== 'true') {
        return `
            height: 100px;
            width: 100px;
            transition: height 0.25s ease-in;
          `;
      }
      return `
          height: 200px;
          width: 200px;
          transition: height 0.25s ease-in;
          `;
    }}
  }
`;

const StyledImg = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const MobileStyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.white};
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
  flex-direction: column;
  transform: translateX(${({ isopen }) => (isopen === 'true' ? '0' : '-100%')});
  transition: transform 0.25s ease-in-out;

  ${({ theme }) => theme.mq.desktop} {
    display: none;
  }
`;

const MobileMenuLinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const MobileMenuLink = styled.li`
  text-decoration: none;
  list-style: none;
  margin: 20px 0;
  opacity: ${({ isopen }) => (isopen === 'true' ? '1' : '0')};
  transition: opacity 0.25s 0.2s ease-in-out;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: ${({ theme }) => theme.font.size.xs};

  :hover:before {
    visibility: visible;
    width: 100%;
  }

  ::before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: black;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  :visited {
    color: ${({ theme }) => theme.black};
  }

  &.active {
    ::before {
      content: '';
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) => theme.black};
      visibility: visible;
      transition: all 0.3s ease-in-out;
      width: 100%;
    }
  }
`;

const menuItemsBefore = [
  {
    name: 'home',
    link: '/',
    activeClassName: 'active',
  },
  {
    name: 'o nas',
    link: '/onas',
    activeClassName: 'active',
  },
  {
    name: 'oferta',
    link: '/oferta',
    activeClassName: 'active',
  },
];

const menuItemsAfter = [
  {
    name: 'galeria',
    link: '/galeria',
    activeClassName: 'active',
  },
  {
    name: 'kontakt',
    link: '/kontakt',
    activeClassName: 'active',
  },
];

const menuItems = [
  {
    name: 'home',
    link: '/',
    activeClassName: 'active',
  },
  {
    name: 'o nas',
    link: '/onas',
    activeClassName: 'active',
  },
  {
    name: 'oferta',
    link: '/oferta',
    activeClassName: 'active',
  },
  {
    name: 'galeria',
    link: '/galeria',
    activeClassName: 'active',
  },
  {
    name: 'kontakt',
    link: '/kontakt',
    activeClassName: 'active',
  },
];

const Nav = () => {
  const pathname = typeof window !== 'undefined' ? window.location.pathname : '';

  const data = useStaticQuery(graphql`
    query LogoFlorystyka {
      logo: sanityLogo(title: { eq: "Florystyka logo" }) {
        title
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  `);

  const [isMenuOpen, setMenuState] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMobileNav = () => {
    setMenuState(!isMenuOpen);
  };

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled);
      }
    };

    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <StyledWrapper
      id="navbar"
      scrollactive={scrolled ? 'true' : 'false'}
      ishomepage={pathname === '/' ? 'true' : 'false'}
    >
      <MenuLinksWrapper>
        {menuItemsBefore.map((item) => (
          <MenuLink
            activeClassName={item.activeClassName}
            key={item.name}
            to={item.link}
          >
            {item.name}
          </MenuLink>
        ))}
      </MenuLinksWrapper>
      <StyledLink
        scrollactive={scrolled ? 'true' : 'false'}
        ishomepage={pathname === '/' ? 'true' : 'false'}
        to="/"
      >
        <StyledImg image={data.logo.image.asset.gatsbyImageData} alt="Lawendowy kącik" />
      </StyledLink>
      <MenuLinksWrapper>
        {menuItemsAfter.map((item) => (
          <MenuLink
            activeClassName={item.activeClassName}
            key={item.name}
            to={item.link}
          >
            {item.name}
          </MenuLink>
        ))}
        <SocialMenu />
      </MenuLinksWrapper>
      <Hamburger
        onClick={toggleMobileNav}
        isOpen={isMenuOpen ? 'true' : 'false'}
      />
      <MobileStyledWrapper isopen={isMenuOpen ? 'true' : 'false'}>
        <MobileMenuLinksWrapper>
          {menuItems.map((item) => (
            <MobileMenuLink
              as={Link}
              activeClassName={item.activeClassName}
              key={item.name}
              to={item.link}
              isopen={isMenuOpen ? 'true' : 'false'}
              onClick={toggleMobileNav}
            >
              {item.name}
            </MobileMenuLink>
          ))}
        </MobileMenuLinksWrapper>
        <SocialMenu />
      </MobileStyledWrapper>
    </StyledWrapper>
  );
};

export default Nav;
