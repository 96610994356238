import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import SocialMenu from './SocialMenu';

const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  padding: 2rem;
  width: 100%;
  position: relative;
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.xs};

  ${({ theme }) => theme.mq.desktop} {
    height: 250px;
    font-size: ${({ theme }) => theme.font.size.s};
  }
`;

const SocialMenuWrapper = styled.div`
  padding: 1rem 0;
`;

const StyledContact = styled.div`
  padding: 1rem 0;
  text-decoration: none;
  border-bottom: 1px solid ${({ theme }) => theme.primaryFloristic};
  display: flex;
  flex-direction: column;
  font-weight: 700;

  ${({ theme }) => theme.mq.desktop} {
    flex-direction: row;
  }
`;

const StyledPolicy = styled.div`
  padding: 1rem 0;
`;

const LinkToSubdomain = styled.div`
  font-style: italic;
`;

const Delimeter = styled.span`
  display: none;
  padding: 0 0.5rem;

  ${({ theme }) => theme.mq.desktop} {
    display: block;
  }
`;

const ContactLink = styled.a`
  text-decoration: none;
  color: black;

  :visited {
    color: black;
  }
`;

const PolicyLink = styled(Link)`
  text-decoration: none;
  color: black;

  :visited {
    color: black;
  }
`;

const CreateByLink = styled.div`
  text-decoration: none;
  color: black;
  padding: 2rem 0 0 0;
  font-size: ${({ theme }) => theme.font.size.xxs};

  :visited {
    color: black;
  }
`;

const Footer = () => (
  <StyledFooter>
    <SocialMenuWrapper>
      <SocialMenu />
    </SocialMenuWrapper>
    <StyledContact>
      <span>Pracownia Florystyczna Lawendowy kącik</span>
      <Delimeter>·</Delimeter>
      <span>ul. Miodowa 6c/8</span>
      <Delimeter>·</Delimeter>
      <span>62-200 Gniezno</span>
      <Delimeter>·</Delimeter>
      <ContactLink href="tel:+485661 248 082">+48 661 248 082</ContactLink>
      <Delimeter>·</Delimeter>
      <ContactLink href="mailto:kwiaciarnia.lawendowykacik@gmail.com">
        kwiaciarnia.lawendowykacik@gmail.com
      </ContactLink>
    </StyledContact>
    <StyledPolicy>
      <span>
        Wszelkie prawa zastrzeżone. Pracownia Florystyczna Lawendowy kącik. |
      </span>
      <PolicyLink to="/polityka-prywatnosci"> Polityka prywatności</PolicyLink>
    </StyledPolicy>
    <LinkToSubdomain>
      Zakładanie i pielęgnacja ogródów{' '}
      <a href="https://www.greener.greener-ak.pl">greener.greener-ak.pl</a>
    </LinkToSubdomain>
    <CreateByLink>
      Strona zaprojektowana i stworzona przez{' '}
      <a href="http://www.wydajna.pl/">wydajna.pl</a>
    </CreateByLink>
  </StyledFooter>
);

export default Footer;
