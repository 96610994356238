// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-boze-narodzenie-js": () => import("./../../../src/pages/oferta/boze-narodzenie.js" /* webpackChunkName: "component---src-pages-oferta-boze-narodzenie-js" */),
  "component---src-pages-oferta-florystyka-funeralna-js": () => import("./../../../src/pages/oferta/florystyka-funeralna.js" /* webpackChunkName: "component---src-pages-oferta-florystyka-funeralna-js" */),
  "component---src-pages-oferta-florystyka-okolicznosciowa-js": () => import("./../../../src/pages/oferta/florystyka-okolicznosciowa.js" /* webpackChunkName: "component---src-pages-oferta-florystyka-okolicznosciowa-js" */),
  "component---src-pages-oferta-florystyka-slubna-js": () => import("./../../../src/pages/oferta/florystyka-slubna.js" /* webpackChunkName: "component---src-pages-oferta-florystyka-slubna-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-oferta-las-w-sloiku-js": () => import("./../../../src/pages/oferta/las-w-sloiku.js" /* webpackChunkName: "component---src-pages-oferta-las-w-sloiku-js" */),
  "component---src-pages-oferta-szkola-cosinus-js": () => import("./../../../src/pages/oferta/szkola-cosinus.js" /* webpackChunkName: "component---src-pages-oferta-szkola-cosinus-js" */),
  "component---src-pages-oferta-wianki-na-glowe-js": () => import("./../../../src/pages/oferta/wianki-na-glowe.js" /* webpackChunkName: "component---src-pages-oferta-wianki-na-glowe-js" */),
  "component---src-pages-onas-js": () => import("./../../../src/pages/onas.js" /* webpackChunkName: "component---src-pages-onas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-templates-gallery-page-js": () => import("./../../../src/templates/GalleryPage.js" /* webpackChunkName: "component---src-templates-gallery-page-js" */)
}

